<script setup lang='ts'>
import type { TimeState } from './hooks'
import { getVerification } from './hooks'

const props = withDefaults(defineProps<{
  phone?: string // 手机号
  seconds?: number // 倒计时长
  onPreHandle?: () => Promise<boolean>
  uuid: string
  code: string
  getImgCode?: Function
}>(), {
  seconds: 60,
})

const timeState = ref<TimeState>('start')
const timeTips = ref<Record<TimeState, string>>({
  start: '获取验证码',
  end: '重新获取',
  loading: '获取中...',
  load: '请稍后',
})

const time = computed(() => props.seconds * 1000)
function timeFinish() {
  timeState.value = 'end'
}
async function getCode() {
  if (['load', 'loading'].includes(timeState.value))
    return

  const old = timeState.value
  timeState.value = 'loading'
  const canGetVerification = props.onPreHandle ? await props.onPreHandle?.() : true
  timeState.value = old
  if (!canGetVerification)
    return

  const res = await getVerification(props.uuid, props.phone, props.code)
  if (res)
    timeState.value = 'load'
  else
    props.getImgCode && props.getImgCode()
}
</script>

<template>
  <van-button
    size="small"
    type="primary"
    :loading="timeState === 'loading'"
    loading-text="获取中..."
    :disabled="timeState === 'load'"
    @click="getCode"
  >
    <span v-if="!['load'].includes(timeState)">{{ timeTips[timeState] }}</span>
    <van-count-down v-else :time="time" auto-start @finish="timeFinish">
      <template #default="timeData">
        <span class="text-white">{{ timeData.seconds }} s</span>
      </template>
    </van-count-down>
  </van-button>
</template>
