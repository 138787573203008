import QRCode from 'qrcode'
import type { TimeString, USER_TYPE } from '@qcwp/common'
import { FILE_PRE_HOST_GZ, articleDetailPath, getContentImages, imageTransform } from '@qcwp/common'
import { isArray, isClient, isWechat, removeElementTags } from '@qcwp/utils'
import { DEFAULT_TITLE } from '~~/src/common/site'
import { getSignature } from '~~/src/server/modules/base'
import type { ArticleDetail } from '~~/src/logic/article/detail'

export interface ShareMedia {
  mediaId?: string
  mediaName?: string
  mediaAvatar?: string
  mediaType?: USER_TYPE
  mediaGrade?: string
  isFocus?: boolean
}
export interface ShareBase {
  url: string
  title: string
  images?: string[] | undefined
  content: string
  type?: 'video' | 'music' | 'link' // 分享类型,music、video或link，不填默认为link
  dataUrl?: string // 如果 type 是music或video，则要提供数据链接，默认为空
  lookCount?: number // 阅读量
  liveStartTime?: TimeString // 直播时间
  liveEndTime?: TimeString // 直播时间
  liveLookTotal?: string // 直播观看人数
  liveLikeTotal?: string // 直播点赞数
}
export interface WechatShareOptions {
  link: string
  title: string
  desc: string
  imgUrl: string
  type?: 'video' | 'music' | 'link' // 分享类型,music、video或link，不填默认为link
  dataUrl?: string // 如果 type 是music或video，则要提供数据链接，默认为空
}
export interface WechatMpShareConfig {
  debug?: boolean
  appId: string // 必填，公众号的唯一标识
  nonceStr: string // 必填，生成签名的时间戳
  signature: string // 必填，签名
  timestamp: string // 必填，生成签名的随机串
  url: string
  jsApiList: string[]
}

export class ArticleShareBase implements ShareBase {
  url = ''
  title = ''
  images?: string[] | undefined = undefined
  content = ''
  type?: 'video' | 'music' | 'link' = 'link'
  dataUrl?: string = ''

  wechatMpShareContent: WechatShareOptions | null = null

  wechatMpShareConfig: WechatMpShareConfig = {
    debug: false,
    appId: '',
    nonceStr: '',
    signature: '',
    timestamp: '',
    url: '',
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表
  }

  constructor(data?: ShareBase) {
    // console.log('ArticleShareBase', data)
    if (!data)
      return
    this.url = data.url
    this.title = data.title
    this.images = (data.images && isArray(data.images))
      ? data.images.filter(v => !!v).map(v => imageTransform(v, { format: 'webp', host: FILE_PRE_HOST_GZ }))
      : []
    this.content = data.content
    this.type = data.type
    this.dataUrl = data.dataUrl

    // 生成微信分享内容
    this.wechatMpShareContent = this.generateWechatMpShareContent()
    this.wechatMpShare()
  }

  static generateShareInfo(data: ArticleDetail): ShareBase | null {
    if (!data)
      return null

    const contentImages = getContentImages(data?.articleDetailInfoVO?.articleContent || '')
    let url = isClient ? (window.location.origin + articleDetailPath(data.articleType, { id: data.id, linkUrl: data.linkUrl })) : ''
    if (url.includes('localhost'))
      url = url.replace(/http:\/\/localhost(:\d+)?/, 'https:\/\/www.qcwp.com')

    url = url.split('#')[0]
    return {
      url,
      title: data.title,
      images: [...data.imgs, ...contentImages].filter(v => !!v).map(v => imageTransform(v, { format: 'webp', host: FILE_PRE_HOST_GZ })),
      content: removeElementTags(data.articleDetailInfoVO.articleContent),
      type: 'link',
      dataUrl: data.videoUrl ? imageTransform(data.videoUrl, { format: 'webp' }) : undefined,
      lookCount: data.look || 0,
    }
  }

  /**
   * 生成微信分享内容
   * @param data ArticleDetail
   * @returns WechatShareOptions | null
   */
  generateWechatMpShareContent(data?: ArticleDetail): WechatShareOptions | null {
    if (!data) {
      return {
        link: this.url,
        title: this.title,
        desc: this.content || this.title,
        imgUrl: this.images?.[0] ? imageTransform(this.images?.[0], { width: 100, height: 100, format: 'webp', host: FILE_PRE_HOST_GZ }) : 'https://www.qcwp.com/logo.png',
        type: this.type,
        dataUrl: this.dataUrl,
      }
    }
    return {
      link: articleDetailPath(data.articleType, { id: data.id, linkUrl: data.linkUrl }),
      title: data.title,
      desc: removeElementTags(data.articleDetailInfoVO.articleContent) || this.content || this.title,
      imgUrl: data.imgs.filter(v => !!v).map(v => imageTransform(v, { width: 100, height: 100, format: 'webp', host: FILE_PRE_HOST_GZ }))?.[0] || '',
      type: 'link', // data.articleType === ARTICLE_TYPE.VIDEO ? 'video' : 'link',
      dataUrl: imageTransform(data.videoUrl, { format: 'webp' }) || this.dataUrl || undefined,
    }
  }

  /**
   * 获取微信公众号分享配置
   * @param url
   */
  async getWechatMpShareConfig(url: string) {
    if (!isWechat())
      return
    if (!this.wechatMpShareConfig.signature) {
      const { data } = await getSignature(url)

      this.wechatMpShareConfig = { ...this.wechatMpShareConfig, ...data }
    }
  }

  // 微信公众号分享
  async wechatMpShare(shareCon: Partial<WechatShareOptions> = {}) {
    const { isDesktop } = useDevice()
    if (!isClient || isDesktop)
      return

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const { default: wx } = await import('weixin-js-sdk')

    const share = (shareCon && this.wechatMpShareContent) ? Object.assign(this.wechatMpShareContent, shareCon) : shareCon

    if (!share.link)
      share.link = isClient ? window.location.href : ''

    if (share.link.includes('localhost'))
      share.link = share.link.replace(/http:\/\/localhost(:\d+)?/, 'https:\/\/www.qcwp.com')

    await this.getWechatMpShareConfig(share.link)

    // console.log('config:', { ...this.wechatMpShareConfig, timestamp: String(this.wechatMpShareConfig.timestamp) })

    // console.log('share:', { ...share })
    wx.config({
      ...this.wechatMpShareConfig,
      timestamp: String(this.wechatMpShareConfig.timestamp),
    })
    wx.ready(() => {
      wx.updateAppMessageShareData({
        ...share,
        type: 'link', // 分享视频时m3u8不显示
      })
      wx.updateTimelineShareData({
        ...share,
      })
    })
    wx.error((error: any) => {
      recordError(error)
    })
  }
}

/**
 * 文章分享信息生成
 */
export function useShare(shareBase: ShareBase) {
  // 新浪分享
  const weiboShareUrl = computed(() => generateWeiboShareUrl(shareBase.url, shareBase.title, shareBase.images))
  // qq、qq空间分享
  const qzoneShareUrl = computed(() => generateQzoneShareUrl(shareBase.url, shareBase.title, shareBase.content, shareBase.images))

  const weixinQrcode = ref('')

  const articleShareBase = shallowRef<ArticleShareBase | null>(null)
  const stop = watchEffect(async () => {
    if (shareBase.url) {
      weixinQrcode.value = await generateCode(shareBase.url) || ''
      articleShareBase.value = new ArticleShareBase(shareBase)
    }
  })

  return {
    stop,
    weiboShareUrl, // 微博分享地址
    qzoneShareUrl,
    weixinQrcode, // 微信分享二维码
  }
}

/**
 *  生成微博分享网页跳转链接
 * @param url string 网页地址
 * @param title string 分享标题
 * @param images string[] 分享图片数组
 * @returns string 微博分享网页跳转链接
 */
function generateWeiboShareUrl(url: string, title: string, images?: string[]): string {
  let str = `https://service.weibo.com/share/share.php?url=${url}&title=${title}`
  if (images && images.length) {
    images.forEach((v) => {
      str += `&pic=${imageTransform(v, { format: 'webp', host: FILE_PRE_HOST_GZ })}`
    })
  }
  return str
}
/**
 *  生成qq分享网页跳转链接
 * @param url string 网页地址
 * @param title string 分享标题
 * @param content string 分享内容
 * @param images string[] 分享图片数组
 * @returns string 微博分享网页跳转链接
 */
function generateQzoneShareUrl(url: string, title: string, content: string, images?: string[]) {
  const source = DEFAULT_TITLE.split('-')[0]
  const summary = DEFAULT_TITLE.split('-')[1]
  let str = `https://connect.qq.com/widget/shareqq/index.html?url=${url}&title=${title}&desc=${content.slice(0, 25)}&summary=${summary}&site=${source}`
  if (images && images.length)
    str += `&pics=${imageTransform(images[0], { format: 'webp', host: FILE_PRE_HOST_GZ })}`

  return str
}

/**
 *  生成二维码
 * @param url string
 * @returns qrcode base64
 */
export async function generateCode(url: string, options?: QRCode.QRCodeToDataURLOptions | undefined) {
  try {
    return await QRCode.toDataURL(url, options)
  }
  catch (error) {
    console.error(`generate qrcode error: ${error}`)
  }
}
