<script setup lang='ts'>
const emits = defineEmits<{
  (e: 'refresh'): void
}>()
function scrollTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const { y } = useScroll(window)

function refreshFn() {
  // scrollTop()
  emits('refresh')
}
</script>

<template>
  <div
    class="bg-white shadow-lg border-1 border-gray-1 rounded-full grid place-content-center w-10 h-10 cursor-pointer"
    :class="[y > 100 ? 'grid' : 'hidden']"
    md="w-15 h-15 text-6"
    title="刷新"
    @click="refreshFn"
  >
    <i class="i-carbon:rotate" />
  </div>
</template>
